* {
  box-sizing: border-box;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Helvetica Neue', Helvetica, sans-serif;
  line-height: 1.5;
  margin: 0;
  color: #111;
  background-color: #fff;
}

img {
  max-width: 100%;
  height: auto;
}

svg {
  max-height: 100%;
}

a {
  color: #07c;
}

h1,
h2,
h4,
h5,
h6 {
  font-weight: 600;
  line-height: 1.25;
  margin-top: 1em;
  margin-bottom: .5em;
}

h1 {
  font-size: 32px;
  font-size: 2rem;
}

h2 {
  font-size: 24px;
  font-size: 1.5rem;
}

h4 {
  font-size: 16px;
  font-size: 1rem;
}

h5 {
  font-size: 14px;
  font-size: .875rem;
}

h6 {
  font-size: 12px;
  font-size: .75rem;
}

p,
ul {
  margin-top: 1em;
  margin-bottom: 1em;
}

.h2 {
  font-size: 24px;
  font-size: 1.5rem;
}

.h3 {
  font-size: 20px;
  font-size: 1.25rem;
}

.h6 {
  font-size: 12px;
  font-size: .75rem;
}

.text-decoration-none {
  text-decoration: none;
}

.bold {
  font-weight: bold;
  font-weight: bold;
}

.regular {
  font-weight: normal;
}

.caps {
  text-transform: uppercase;
  letter-spacing: .2em;
}

.center {
  text-align: center;
}

.nowrap {
  white-space: nowrap;
}

.list-reset {
  list-style: none;
  padding-left: 0;
}

.inline-block {
  display: inline-block;
}

.clearfix:before,
.clearfix:after {
  content: " ";
  display: table;
}

.clearfix:after {
  clear: both;
}

.max-width-4 {
  max-width: 1024px;
  max-width: 64rem;
}

.m0 {
  margin: 0;
}

.my0 {
  margin-top: 0;
  margin-bottom: 0;
}

.my1 {
  margin-top: 8px;
  margin-top: .5rem;
  margin-bottom: 8px;
  margin-bottom: .5rem;
}

.mx2 {
  margin-left: 16px;
  margin-left: 1rem;
  margin-right: 16px;
  margin-right: 1rem;
}

.my4 {
  margin-top: 64px;
  margin-top: 4rem;
  margin-bottom: 64px;
  margin-bottom: 4rem;
}

.mxn2 {
  margin-left: -16px;
  margin-left: -1rem;
  margin-right: -16px;
  margin-right: -1rem;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.p1 {
  padding: 8px;
  padding: .5rem;
}

.px1 {
  padding-left: 8px;
  padding-left: .5rem;
  padding-right: 8px;
  padding-right: .5rem;
}

.p2 {
  padding: 16px;
  padding: 1rem;
}

.px2 {
  padding-left: 16px;
  padding-left: 1rem;
  padding-right: 16px;
  padding-right: 1rem;
}

.flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.items-end {
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.justify-center {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.justify-between {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.border {
  border-style: solid;
  border-width: 1px;
}

.border-top {
  border-top-style: solid;
  border-top-width: 1px;
}

.border-bottom {
  border-bottom-style: solid;
  border-bottom-width: 1px;
}

.h00 {
  font-size: 64px;
  font-size: 4rem;
}

.black {
  color: #111111;
}

.bg-cover {
  background-size: cover;
}

.bg-center {
  background-position: center;
}

@font-face {
  font-family: "fontello";
  src: url("../font/fontello.eot?70276884");
  src: url("../font/fontello.eot?70276884#iefix") format("embedded-opentype"), url("../font/fontello.woff2?70276884") format("woff2"), url("../font/fontello.woff?70276884") format("woff"), url("../font/fontello.ttf?70276884") format("truetype"), url("../font/fontello.svg?70276884#fontello") format("svg");
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"]:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 1em;
  margin-left: .2em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-shadow: 1px 1px 1px rgba(127, 127, 127, .3);
}

.icon-twitter:before {
  content: "\f099";
}

.icon-github-circled:before {
  content: "\f09b";
}

.icon-facebook-official:before {
  content: "\f230";
}

body {
  font-family: 'Inconsolata', monospace;
}

header {
  background-image: url("../img/min/bg-1024.jpg");
  filter: url('data:image/svg+xml;charset=utf-8, <svg xmlns="http://www.w3.org/2000/svg"><filter id="filter"><feGaussianBlur in="SourceAlpha" stdDeviation="4" /><feOffset dx="1" dy="3" result="offsetblur" /><feFlood flood-color="rgba(0, 0, 0, 1)" /><feComposite in2="offsetblur" operator="in" /><feMerge><feMergeNode /><feMergeNode in="SourceGraphic" /></feMerge></filter></svg>#filter');
  -webkit-filter: drop-shadow(0 2px 4px);
  filter: drop-shadow(0 2px 4px);
}

header a:hover {
  color: whitesmoke;
}

.header-content * {
  font-family: "Roboto Mono", sans-serif;
  line-height: 0.9;
}

svg {
  fill: currentColor;
  height: 3em;
}

a {
  transition: 0.5s ease;
}

a:hover {
  color: grey;
}

#images a,
h1,
h2 {
  text-shadow: 1px 1px 1px rgba(127, 127, 127, .3);
}

ul.tag li {
  transition: 0.5s;
  cursor: default;
  background-color: white;
}

ul.tag li:hover {
  -webkit-transform: scale(1.25);
  transform: scale(1.25);
}

.caps-r {
  text-transform: uppercase;
}

.gh:hover:after {
  content: " GitHub ";
  font-weight: bolder;
  font-size: smaller;
}

.gh:hover:before {
  content: none;
}

.tw:hover:after {
  content: " Twitter ";
  font-weight: bolder;
  font-size: smaller;
}

.tw:hover:before {
  content: none;
}

.fb:hover:after {
  content: " Facebook ";
  font-weight: bolder;
  font-size: smaller;
}

.fb:hover:before {
  content: none;
}

.fade-box {
  position: relative;
}

.overlay {
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.blur-up {
  filter: url('data:image/svg+xml;charset=utf-8, <svg xmlns="http://www.w3.org/2000/svg"><filter id="filter"><feGaussianBlur stdDeviation="5" /></filter></svg>#filter');
  -webkit-filter: blur(5px);
  filter: blur(5px);
  transition: all 0.5s ease;
}

.blur-up.lazyloaded {
  filter: url('data:image/svg+xml;charset=utf-8, <svg xmlns="http://www.w3.org/2000/svg"><filter id="filter"><feGaussianBlur stdDeviation="0" /></filter></svg>#filter');
  -webkit-filter: blur(0);
  filter: blur(0);
  transition: 0.5s ease;
  opacity: 1;
  display: block;
  width: 100%;
  height: auto;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.fade-box .lazyload,
.fade-box .lazyloading {
  opacity: 0;
  transition: opacity 400ms;
}

.fade-box img.lazyloaded {
  opacity: 1;
}

.fade-box:hover img.lazyloaded {
  opacity: 0.3;
}

.fade-box:hover figcaption.overlay {
  opacity: 1;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

@media (min-width: 40em) {
  .sm-col {
    float: left;
    box-sizing: border-box;
  }

  .sm-col-6 {
    width: 50%;
  }

  .sm-col-12 {
    width: 100%;
  }

  .sm-flex {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }

  .sm-mr3 {
    margin-right: 2rem;
  }

  .sm-ml3 {
    margin-left: 2rem;
  }

  .sm-my3 {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .sm-left-align {
    text-align: left;
  }

  .sm-h1 {
    font-size: 2rem;
  }

  .sm-h2 {
    font-size: 1.5rem;
  }

  .sm-header-content {
    margin-top: 1em;
  }

  .sm-subhead {
    margin-top: 5vw;
    margin-bottom: 5vw;
  }
}

@media (min-width: 64em) {
  .lg-col-4 {
    width: 33.33333%;
  }

  header {
    background: url("../img/min/bg-1900.jpg");
  }
}

@media (min-width: 52em) {
  .h00-responsive {
    font-size: 8vw;
  }

  .md-h2 {
    font-size: 1.5rem;
  }
}

@media (min-width: 96em) {
  .h00-responsive {
    font-size: 7.68rem;
  }
}

@media (max-width: 40em) {
  .xs-logo {
    height: 2em;
  }

  .xs-header {
    text-align: left;
    margin-left: 0;
  }
}



